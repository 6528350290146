import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
	AdminUserPermissionsEnum,
	GenderEnum,
	UserAccountStatusEnum,
	VerifStatusEnum,
} from '@/lib/enum.ts';
import { IConnectedUser, IUserVerification } from '@/lib/interface.ts';

dayjs.extend(relativeTime);

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function jsonToQueryString(json: Record<string, unknown>) {
	return Object.keys(json)
		.map(function (key) {
			return encodeURIComponent(key) + '=' + encodeURIComponent(json[key] as string);
		})
		.join('&');
}

export function getUserCoinCount(userCoins: number, walletCoins?: number): number {
	if (!isNumber(userCoins)) return 0;
	return userCoins + (walletCoins || 0);
}

export function userHasPermission(
	user: IConnectedUser | null,
	permission: AdminUserPermissionsEnum,
): boolean {
	if (!user || !user?.permissions || user?.permissions?.length === 0) return false;
	return user.permissions.includes(permission);
}

export function isNumber(nbr: number) {
	return nbr !== undefined && nbr !== null && nbr.constructor === Number;
}

export function isNull(value: unknown): boolean {
	return value === null || value === undefined;
}

export function isBanned(accountStatus: UserAccountStatusEnum): boolean {
	return (
		accountStatus === UserAccountStatusEnum.BANNED ||
		accountStatus === UserAccountStatusEnum.HIDDEN
	);
}

export function getGenderString(gender: GenderEnum): string {
	if (gender === GenderEnum.MALE) return 'Homme';
	else return 'Femme';
}

export function canBanDevice(deviceId: string): boolean {
	if (isEmptyString(deviceId)) return false;
	const regex1 = /^[0-9a-zA-Z]{16}$/;
	const regex2 =
		/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/;
	return regex1.test(deviceId) || regex2.test(deviceId);
}

export function sortByStatus(a: any, b: any) {
	if (a.status === VerifStatusEnum.PENDING && b.status !== VerifStatusEnum.PENDING) {
		return 1;
	} else if (
		a.status !== VerifStatusEnum.PENDING &&
		b.status === VerifStatusEnum.PENDING
	) {
		return -1;
	} else {
		return 0;
	}
}

export function getAge(birthdate?: string | Date): number {
	if (!birthdate) return 0;
	const today = new Date();
	const date = new Date(birthdate);

	let age = today.getFullYear() - date.getFullYear();
	const monthDiff = today.getMonth() - date.getMonth();

	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
		age--;
	}

	return age;
}

export function isEmptyString(str: string | undefined | null) {
	if (!isString(str)) return true;
	return str?.trim().length === 0;
}

export function isBioValid(verifs?: IUserVerification): boolean {
	if (!verifs?.bio) return true;
	return verifs?.bio?.status && verifs?.bio?.status !== VerifStatusEnum.DENIED;
}

export function isCertified(verifs?: IUserVerification): boolean {
	if (!verifs?.id) return false;
	return verifs?.id?.status === VerifStatusEnum.COMPLETED;
}

export function isCertifPending(verifs?: IUserVerification): boolean {
	if (!verifs?.id) return false;
	return verifs?.id?.status === VerifStatusEnum.PENDING;
}

export const getFullDateStripped = (date: Date, language = 'fr') => {
	/* eg: 12 Mai 2023 */
	let defaultLocale = 'fr';
	let defaultFormat = 'DD MMM YYYY';
	if (language && date) {
		if (language.startsWith('en')) {
			defaultLocale = 'en';
			defaultFormat = 'MMM DD, YYYY';
		}
		return dayjs(date).locale(defaultLocale).format(defaultFormat);
	} else return '';
};

export function getCertificationBadge(verifs?: IUserVerification): string {
	if (!verifs?.id) return '';
	if (isCertified(verifs)) return '✅|';
	return '';
}

export function wait(seconds: number) {
	return new Promise(resolve => {
		setTimeout(resolve, seconds * 1000);
	});
}

export function getMemberSince(createdAt: Date): string {
	if (!createdAt) return '';
	const formattedDate = dayjs(createdAt).locale('fr').format('MMMM YYYY');
	return `Membre depuis ${formattedDate}`;
}

export function getDayMmYear(createdAt: Date): string {
	if (!createdAt) return '';
	return dayjs(createdAt).locale('fr').format('DD MMM YYYY');
}

export function getLastConnection(lastSeen: Date): string {
	if (!lastSeen) return '';
	const formattedDate = dayjs().locale('fr').to(dayjs(lastSeen));
	return `Dernière connexion ${formattedDate}`;
}

export function getTimeSince(lastSeen: Date, preText?: string): string {
	if (!lastSeen) return '';
	const formattedDate = dayjs().locale('fr').to(dayjs(lastSeen));
	return `${preText} ${formattedDate}`;
}

export function isString(obj: unknown) {
	return obj !== undefined && obj !== null && obj.constructor === String;
}

export const getIdPhotoRef = (sourceName: string) => {
	return getPhotoUrl(`verifications/${sourceName.toLowerCase()}.png`, 'public');
};
export const getPhotoUrl = (photoPath?: string, bucketName?: string) => {
	let endpoint = 'https://asset.heyama.app';
	if (bucketName === 'public' || isEmptyString(photoPath)) {
		endpoint = 'https://asset-pub.heyama.app';
	} else if (import.meta.env.VITE_APP_ENV.includes('dev')) {
		endpoint = 'https://asset-dev.heyama.app';
	} else if (import.meta.env.VITE_APP_ENV.includes('staging')) {
		endpoint = 'https://asset-stg.heyama.app';
	}
	if (isEmptyString(photoPath)) {
		return `${endpoint}/images/default_profile.png`;
	} else {
		return `${endpoint}/${photoPath}`;
	}
};
