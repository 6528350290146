import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from '@/components/ui/card.tsx';
import { jwtDecode } from 'jwt-decode';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar.tsx';
import { Link, Navigate } from 'react-router-dom';
import { useLogin } from '@/components/api/swrQueries.tsx';
import { IGoogleUser } from '@/lib/interface.ts';
import Loader from '@/components/custom/Loader.tsx';
import userStore from '@/store/userStore.ts';
import routes from '@/lib/routes.tsx';

export default function LoginPage() {
	const user = userStore(state => state.user);
	const { trigger: login, isMutating: isLoginLoading } = useLogin();

	const googleResponse = async (response: CredentialResponse) => {
		const responseDecoded: IGoogleUser = jwtDecode(response.credential!);
		await login({
			email: responseDecoded.email,
			name: responseDecoded.name,
		});
	};

	if (user) {
		return <Navigate to={routes.nav.home} />;
	}

	return (
		<div
			className={'h-screen w-screen flex flex-col gap-y-3 justify-center items-center'}
		>
			<Avatar className={'w-14 h-14'}>
				<AvatarImage src={'/logo.png'} />
				<AvatarFallback>LG</AvatarFallback>
			</Avatar>

			<Card className="w-[350px] justify-center items-center">
				<CardHeader className={'items-center'}>
					<CardTitle>Heyama Admin</CardTitle>
					<CardDescription>Connectez-vous pour continuer.</CardDescription>
				</CardHeader>
				<CardContent className={'flex flex-col items-center justify-center'}>
					<GoogleLogin
						size={'large'}
						onSuccess={googleResponse}
						onError={() => {
							console.log('Login Failed');
						}}
					/>
					{isLoginLoading && <Loader className={'mt-4'} />}
				</CardContent>
				<CardFooter>
					<p className="px-8 text-center text-sm text-muted-foreground">
						Un problème? Contactez nous{' '}
						<Link
							to={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
							className="underline underline-offset-4 hover:text-primary"
						>
							{import.meta.env.VITE_CONTACT_EMAIL}
						</Link>
					</p>
				</CardFooter>
			</Card>
		</div>
	);
}
