import { HTMLProps } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Card, CardContent, CardFooter } from '@/components/ui/card.tsx';
import { IReport } from '@/lib/interface.ts';
import UserPhotoGrid from '@/components/custom/UserPhotoGrid.tsx';
import { canBanDevice, getCertificationBadge, getGenderString } from '@/lib/utils.ts';
import ReporterCardItem from '@/components/custom/ReporterCardItem.tsx';
import { BanTypeEnum } from '@/lib/enum.ts';
import { useNavigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';

interface Interface {
	item: IReport;
	onWarnReporter: (reporterUserId: string, reportId: string) => void;
	onWarnReported: (reportedUserId: string, reportId: string) => void;
	onIgnoreReport: (reportedUserId: string, reportId: string) => void;
	onBanReportedUser: (
		reportedUserId: string,
		banType: BanTypeEnum,
		reportId: string,
	) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ReportItem({
	item,
	className,
	onIgnoreReport,
	onWarnReporter,
	onWarnReported,
	onBanReportedUser,
}: Interface) {
	const navigate = useNavigate();

	const showProfileDetails = (userId: string) => {
		navigate(`${routes.nav.profileDetails}/${userId}`);
	};

	return (
		<Card className={`mx-2 w-full ${className}`}>
			<div className={'px-4 py-2 flex flex-col'}>
				<span
					className={'font-semibold cursor-pointer'}
					onClick={() => showProfileDetails(item?.userData._id)}
				>
					{getCertificationBadge(item.userData.verifications)} {item.userData.username}
				</span>
				<span className={'text-sm text-gray-400'}>
					{getGenderString(item.userData.gender)} • {item.userData.accountStatus}{' '}
					{item.behaviorWarningSent! > 0 &&
						`• Avertissements: ${item.behaviorWarningSent} ${
							item.userData.deviceAccountsCount! > 1
								? `• N° de comptes: ${item.userData.deviceAccountsCount}`
								: ''
						}`}
				</span>
			</div>

			<Separator />

			<CardContent className={'py-4 px-2 md:px-4'}>
				<UserPhotoGrid
					withButtons={false}
					userData={item.userData}
					cardClassName={'w-[170px] md:w-[140px]'}
				/>
			</CardContent>

			<Separator />

			<CardContent className={'flex flex-col py-4 px-2 gap-3 md:px-4'}>
				{item.reporters.map((reporter, index) => (
					<ReporterCardItem
						key={index}
						item={reporter}
						onWarnUserClick={() => onWarnReporter(reporter.reporter, item._id)}
						onClickName={userId => showProfileDetails(userId)}
					/>
				))}
			</CardContent>

			<Separator />

			<CardFooter
				className={'flex flex-row items-center justify-center md:justify-end p-4'}
			>
				<div className={'flex flex-row flex-wrap justify-end items-center gap-3'}>
					<Button
						variant={'secondary'}
						onClick={() => onWarnReported(item.userData._id, item._id)}
					>
						Avertir ⚠️
					</Button>
					<Button
						variant={'secondary'}
						onClick={() => onIgnoreReport(item.userData._id, item._id)}
					>
						Ignorer 🗑️
					</Button>
					{/* Ban Btn */}
					<Popover>
						<PopoverTrigger asChild>
							<Button variant={'destructive'}>Bannir 🫡</Button>
						</PopoverTrigger>
						<PopoverContent align={'end'} className={'flex flex-col gap-4'}>
							<h1 className={'text-sm'}>Que voulez-vous bannir ?</h1>
							<div className={'flex flex-row justify-between gap-4'}>
								<Button
									variant={'secondary'}
									className={'w-full'}
									onClick={() =>
										onBanReportedUser(item.userData._id, BanTypeEnum.EMAIL_BAN, item._id)
									}
								>
									Email 📧
								</Button>
								{canBanDevice(item.userData.deviceId) ? (
									<Button
										variant={'destructive'}
										className={'w-full'}
										onClick={() =>
											onBanReportedUser(
												item.userData._id,
												BanTypeEnum.DEVICE_BAN,
												item._id,
											)
										}
									>
										Téléphone 📱
									</Button>
								) : null}
							</div>
						</PopoverContent>
					</Popover>
				</div>
			</CardFooter>
		</Card>
	);
}
