import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import HomeCard from '@/components/custom/HomeCard.tsx';
import {
	BadgeCheck,
	BarChartBig,
	Image,
	MessageCircleWarning,
	ScanBarcode,
	Text,
	Undo2,
	UserRoundSearch,
} from 'lucide-react';
import userStore from '@/store/userStore.ts';
import {
	useDashboardData,
	useMaintenance,
	useToggleMaintenance,
} from '@/components/api/swrQueries.tsx';
import routes from '@/lib/routes.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { AdminUserPermissionsEnum, GenderEnum } from '@/lib/enum.ts';
import CustomSwitch from '@/components/custom/Switch.tsx';
import { useEffect, useState } from 'react';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';
import dayjs from 'dayjs';
import { userHasPermission } from '@/lib/utils.ts';

export default function HomePage() {
	const navigate = useNavigate();
	const user = userStore(state => state.user);
	const { data: maintenanceState } = useMaintenance();
	const { trigger: toggleMaintenance } = useToggleMaintenance();
	const { data: cardsData, isLoading: cardDataLoading } = useDashboardData();
	const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);

	useEffect(() => {
		if (typeof maintenanceState === 'boolean') {
			setIsMaintenanceActive(maintenanceState);
		}
	}, [maintenanceState]);

	const navTo = (route: string) => {
		navigate(route);
	};

	const handleToggleMaintenance = async (checked: boolean) => {
		const yes = await confirm(
			checked ? 'Activer la maintenance ?' : 'Désactiver la maintenance ?',
		);
		if (!yes) return;
		setIsMaintenanceActive(checked);
		/* Make the request */
		await toggleMaintenance();
	};

	return (
		<div className={'md:container mx-3 mb-3'}>
			{/* Top Card */}
			<Card>
				<CardHeader className={'items-center'}>
					<CardTitle>Bienvenue {user?.name}</CardTitle>
					<CardDescription className={'text-center'}>
						Réglez les vérifications le plus rapidement possible. N'attendez pas qu'il y
						en ai plusieurs pour commencer.
					</CardDescription>
					{userHasPermission(user, AdminUserPermissionsEnum.MAINTENANCE_SWITCH) ? (
						<CustomSwitch
							className={'pt-3'}
							color={'bg-red-500'}
							checked={isMaintenanceActive}
							onCheckedChange={handleToggleMaintenance}
							label={`Maintenance en cours: ${isMaintenanceActive ? 'OUI' : 'NON'}`}
						/>
					) : null}
				</CardHeader>
			</Card>
			{/* Tiles */}
			<div
				className={'grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 mt-3 md:mt-8 lg:mx-20'}
			>
				{/* Card */}
				{userHasPermission(user, AdminUserPermissionsEnum.CERTIFICATION) ? (
					<HomeCard
						value={cardsData?.id}
						label={'Certifications'}
						isLoading={cardDataLoading}
						icon={<BadgeCheck size={60} strokeWidth={1} />}
						onClick={() =>
							navTo(`${routes.nav.certifications}?gender=${GenderEnum.MALE}`)
						}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.PHOTO_VERIF) ? (
					<HomeCard
						label={'Photos'}
						value={cardsData?.photo}
						isLoading={cardDataLoading}
						icon={<Image size={60} strokeWidth={1} />}
						onClick={() => navTo(`${routes.nav.verifications}?gender=${GenderEnum.MALE}`)}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.BIO_VERIF) ? (
					<HomeCard
						label={'Bio'}
						value={cardsData?.bio}
						isLoading={cardDataLoading}
						icon={<Text size={60} strokeWidth={1} />}
						onClick={() => navTo(routes.nav.bioReview)}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.REPORT) ? (
					<HomeCard
						label={'Signalements'}
						value={cardsData?.reports}
						isLoading={cardDataLoading}
						onClick={() => navTo(routes.nav.reports)}
						icon={<MessageCircleWarning size={60} strokeWidth={1} />}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.STATS) ? (
					<Link to={routes.links.stats} target={'_blank'}>
						<HomeCard
							label={'Statistiques'}
							icon={<BarChartBig size={60} strokeWidth={1} />}
						/>
					</Link>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.USERS) ? (
					<HomeCard
						label={'Utilisateurs'}
						onClick={() => navTo(routes.nav.findUser)}
						icon={<UserRoundSearch size={60} strokeWidth={1} />}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.REFERRAL) ? (
					<HomeCard
						label={'Parrainage'}
						onClick={() => navTo(routes.nav.referral)}
						icon={<ScanBarcode size={60} strokeWidth={1} />}
					/>
				) : null}
				{userHasPermission(user, AdminUserPermissionsEnum.HISTORY) ? (
					<HomeCard
						label={'Historique'}
						icon={<Undo2 size={60} strokeWidth={1} />}
						onClick={() =>
							navTo(
								`${routes.nav.verificationsHistory}?page=1&date=${dayjs().format(
									'YYYY-MM-DD',
								)}`,
							)
						}
					/>
				) : null}
			</div>
		</div>
	);
}
