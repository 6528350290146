import useSWR from 'swr';
import routes from '@/lib/routes.tsx';
import {
	IBioVerification,
	ICertificationCount,
	ICertifications,
	IConnectedUser,
	IDashboardData,
	IReferralUsers,
	IReport,
	ISearchResponse,
	ISearchUserParams,
	ISearchVerifResponse,
	IUserData,
	IVerifications,
	Paginated,
	TCertificationParams,
	TVerificationDoneParams,
} from '@/lib/interface.ts';
import userStore from '@/store/userStore.ts';
import useSWRMutation from 'swr/mutation';
import {
	banUser,
	createReferralUser,
	deleteProfilePicture,
	deleteReferralUser,
	finishCertification,
	finishVerification,
	ignoreReport,
	login,
	toggleMaintenance,
	updateUserProfile,
	verifyBio,
	warnUser,
} from '@/components/api/requests.ts';
import useNotification from '@/components/hooks/useNotification.tsx';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { apiClient } from '@/components/api/apiClient.ts';
import { jsonToQueryString } from '@/lib/utils.ts';

export const useDashboardData = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<IDashboardData>(user ? routes.api.dashboard : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useMaintenance = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<IDashboardData>(user ? routes.api.getMaintenance : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useCertificationCount = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<ICertificationCount>(user ? routes.api.getCertificationCount : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useBioVerification = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<IBioVerification[]>(user ? routes.api.getBioVerifications : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useVerificationCount = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<ICertificationCount>(user ? routes.api.getVerificationCount : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useGetCertifications = (params: TCertificationParams) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);

	return useSWR<ICertifications[]>(
		user ? `${routes.api.getCertifications}?${jsonToQueryString(params)}` : null,
		{
			onError: err => showNotification(err?.internalMessage, 'error'),
		},
	);
};

export const useGetVerifications = (params: TCertificationParams) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<IVerifications[]>(
		user ? `${routes.api.getVerifications}?${jsonToQueryString(params)}` : null,
		{ onError: err => showNotification(err?.internalMessage, 'error') },
	);
};

export const useGetUsers = (params: ISearchUserParams) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<ISearchResponse>(
		user ? `${routes.api.searchUser}?${jsonToQueryString(params)}` : null,
		{
			onError: err => showNotification(err?.internalMessage, 'error'),
		},
	);
};

export const useGetVerificationsCompleted = (params: TVerificationDoneParams) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<ISearchVerifResponse>(
		user ? `${routes.api.getVerificationsCompleted}?${jsonToQueryString(params)}` : null,
		{ onError: err => showNotification(err?.internalMessage, 'error') },
	);
};

export const useGetReports = () => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<IReport[]>(user ? routes.api.getReports : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useGetUserById = (id: string) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<IUserData>(user ? `${routes.api.getUserById}?userId=${id}` : null, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useGetUserSubAccounts = (id: string) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<IUserData[]>(
		user ? `${routes.api.getUserSubAccounts}?userId=${id}` : null,
		{
			onError: err => showNotification(err?.internalMessage, 'error'),
		},
	);
};

export const useFinishCertification = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.finishCertification, finishCertification, {
		onError: err => showNotification(err?.internalMessage || err.message, 'error'),
	});
};

export const useFinishVerification = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.finishVerification, finishVerification, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useCreateReferralUser = () => {
	const showNotification = useNotification();
	const { mutate } = useGetReferralUsers(1);
	return useSWRMutation(routes.api.createReferralUser, createReferralUser, {
		onSuccess: () => mutate(),
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useGetReferralUsers = (page: number) => {
	const showNotification = useNotification();
	const user = userStore(state => state.user);
	return useSWR<Paginated<IReferralUsers>>(
		user ? `${routes.api.getReferralUsers}?page=${page}` : null,
		{
			onError: err => showNotification(err?.internalMessage, 'error'),
		},
	);
};

export const useDeleteReferralUser = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.deleteReferralUser, deleteReferralUser, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification("l'influenceur a été supprimé"),
	});
};

export const useLogin = () => {
	const showNotification = useNotification();
	const navigate = useNavigate();
	const setStoreUser = userStore(state => state.setStoreUser);

	return useSWRMutation(routes.api.login, login, {
		onSuccess: data => {
			const userData: IConnectedUser = jwtDecode(data.token);
			apiClient.setHeader('Authorization', `Bearer ${data.token}`);
			showNotification('Connexion réussie');
			setStoreUser({
				_id: userData._id,
				name: data.name,
				token: data.token,
				permissions: userData.permissions,
			});
			navigate(routes.nav.home);
		},
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useDeleteUserPicture = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.deleteProfilePicture, deleteProfilePicture, {
		onError: err => showNotification(err?.internalMessage, 'error'),
	});
};

export const useUpdateUserProfile = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.updateUserProfile, updateUserProfile, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Profil mis à jour'),
	});
};

export const useVerifyBio = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.verifyBio, verifyBio, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Vérification de la bio a été effectuée'),
	});
};

export const useWarnUser = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.warnUser, warnUser, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Le profil a été averti.'),
	});
};

export const useIgnoreReport = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.ignoreReport, ignoreReport, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Signalement ignoré.'),
	});
};

export const useBanUser = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.banUser, banUser, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Le profil a été banni.'),
	});
};

export const useToggleMaintenance = () => {
	const showNotification = useNotification();
	return useSWRMutation(routes.api.toggleMaintenance, toggleMaintenance, {
		onError: err => showNotification(err?.internalMessage, 'error'),
		onSuccess: () => showNotification('Maintenance actualisée.'),
	});
};
