import { HTMLProps } from 'react';
import { Card, CardHeader } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { IBioVerification } from '@/lib/interface.ts';
import { getGenderString } from '@/lib/utils.ts';
import { useNavigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';
import NbrOfAccountsText from '@/components/custom/NbrOfAccountsText.tsx';
import { Separator } from '@/components/ui/separator.tsx';

interface Interface {
	item: IBioVerification;
	isLoading: boolean;
	onclickBtn: (verifId: string, accept: boolean) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function BioVerificationItem({
	item,
	isLoading,
	onclickBtn,
	className,
}: Interface) {
	const navigate = useNavigate();
	const showProfileDetails = (userId: string) => {
		navigate(`${routes.nav.profileDetails}/${userId}`);
	};

	return (
		<Card className={`w-[370px] ${className}`}>
			<CardHeader className={'p-4'}>
				<h1
					className={'text-2xl font-semibold cursor-pointer'}
					onClick={() => showProfileDetails(item.userData?._id)}
				>
					{item.userData?.username} • {getGenderString(item.userData?.gender)}
				</h1>
				<NbrOfAccountsText
					className={'text-start'}
					userId={item?.userData?._id}
					deviceAccountsCount={item?.userData?.deviceAccountsCount}
				/>
				<Separator />
				<h2 className={`text-md text-gray-400`}>{item.aboutYou}</h2>
			</CardHeader>

			{/* Buttons */}
			<div className={'flex flex-row items-center justify-center gap-x-5 px-4 pb-4 pt-0'}>
				<Button
					disabled={isLoading}
					variant={'default'}
					className={'w-full text-red-500 bg-red-100 hover:bg-red-200/80'}
					onClick={() => onclickBtn(item._id, false)}
				>
					Refuser
				</Button>
				<Button
					disabled={isLoading}
					variant={'default'}
					className={'w-full text-green-600 bg-green-100 hover:bg-green-200/80'}
					onClick={() => onclickBtn(item._id, true)}
				>
					Accepter
				</Button>
			</div>
		</Card>
	);
}
