import { HTMLProps } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { useNavigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';

interface Interface {
	userId: string;
	deviceAccountsCount: number;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function NbrOfAccountsText({
	deviceAccountsCount,
	userId,
	className,
}: Interface) {
	const navigate = useNavigate();

	const goToSubAccounts = () => {
		navigate(`${routes.nav.profileDetails}/${userId}/${routes.nav.subAccounts}`);
	};

	return deviceAccountsCount > 1 ? (
		<span className={`text-center text-sm text-gray-500 ${className}`}>
			🆕 Nombre de comptes actifs: {deviceAccountsCount}
			{deviceAccountsCount >= 2 && (
				<Button size="sm" variant="link" onClick={goToSubAccounts}>
					Voir
				</Button>
			)}
		</span>
	) : null;
}
