import { HTMLProps } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { useNavigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';

interface Interface {
	className?: HTMLProps<HTMLElement>['className'];
}

export default function PageNotFound({ className }: Interface) {
	const navigate = useNavigate();

	return (
		<div
			className={
				'h-screen w-screen flex flex-col gap-y-12 justify-center items-center' + className
			}
		>
			<h1 className={'text-4xl font-bold text-center'}>Désolé, page introuvable.</h1>
			<div className={'self-center'}>
				<Button onClick={() => navigate(routes.nav.home)}>Retourner à l'accueil</Button>
			</div>
		</div>
	);
}
