import { HTMLProps } from 'react';
import { Card, CardDescription, CardHeader } from '@/components/ui/card.tsx';
import { IReferralUsers } from '@/lib/interface.ts';
import { getDayMmYear, userHasPermission } from '@/lib/utils.ts';
import { AdminUserPermissionsEnum, GenderEnum } from '@/lib/enum.ts';
import { Button } from '@/components/ui/button.tsx';
import userStore from '@/store/userStore.ts';

interface Interface {
	user: IReferralUsers;
	onDeletePress: (user: IReferralUsers) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ReferralUserItem({ user, onDeletePress, className }: Interface) {
	const loginUser = userStore(state => state.user);

	return (
		<Card
			className={`flex flex-row w-full items-center justify-between p-3 gap-3 ${className}`}
		>
			<CardHeader className={'p-0'}>
				<h1 className={'font-semibold text-purple-600'}>
					{user.gender === GenderEnum.MALE ? '🧔🏽‍♂' : '👩🏽'} {user.username}
				</h1>
				<CardDescription>
					Code de parrainage:{' '}
					<span className={'font-semibold'}>{user.extra?.referralCode}</span>
				</CardDescription>
				<CardDescription>
					Partenaire depuis:{' '}
					<span className={'font-semibold'}>{getDayMmYear(user.createdAt)}</span>
				</CardDescription>
			</CardHeader>

			<div className={'text-center cursor-pointer flex flex-col gap-y-2'}>
				{/* Code */}
				<Card
					className={
						'text-center p-3 flex flex-col items-center justify-center cursor-pointer'
					}
				>
					<h1 className={'font-semibold text-purple-600'}>{user.referralCount}</h1>
					<CardDescription>Parrainé(s)</CardDescription>
				</Card>
				{/* delete btn */}
				{userHasPermission(loginUser, AdminUserPermissionsEnum.REFERRAL_DELETE) && (
					<Button
						size={'sm'}
						/*disabled={isLoading}*/
						variant={'default'}
						onClick={() => onDeletePress(user)}
						className={'text-red-500 bg-red-100 hover:bg-red-200/80'}
					>
						Supprimer
					</Button>
				)}
			</div>
		</Card>
	);
}
