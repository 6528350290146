import { HTMLProps } from 'react';
import { isEmptyString } from '@/lib/utils.ts';
import NbrOfAccountsText from '@/components/custom/NbrOfAccountsText.tsx';

interface Interface {
	reviewerName?: string;
	hasIncognito?: boolean;
	hideTitle?: boolean;
	userId: string;
	coins?: number;
	deviceBanCount: number;
	deviceAccountsCount?: number;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ProfileBottomStats({
	coins,
	userId,
	hasIncognito,
	reviewerName,
	hideTitle = false,
	deviceAccountsCount = 0,
	deviceBanCount,
	className,
}: Interface) {
	return (
		<div className={`flex flex-col items-center justify-center gap-1 ${className}`}>
			{hideTitle ? null : (
				<span className={'text-center font-semibold'}>Plus d'infos</span>
			)}
			{coins! > 0 ? (
				<span className={'text-center text-sm text-gray-500'}>
					🪙 Pièces: {coins!.toLocaleString()}
				</span>
			) : null}
			{hasIncognito ? (
				<span className={'text-center text-sm text-gray-500'}>🥸 Incognito: OUI</span>
			) : null}
			{deviceBanCount > 0 ? (
				<span className={'text-center text-sm text-gray-500'}>
					🚫 Deja banni: {deviceBanCount} fois
				</span>
			) : null}
			<NbrOfAccountsText deviceAccountsCount={deviceAccountsCount} userId={userId} />
			{!isEmptyString(reviewerName) ? (
				<span className={'text-center text-sm text-gray-500'}>
					✅ Certifié par: {reviewerName}
				</span>
			) : null}
		</div>
	);
}
